import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { MapContainer, useMap, useMapEvents } from 'react-leaflet';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getStatusColour,
  statusToString,
  statuses,
} from '../../../../shared/utilities';
import { useHistory, useLocation } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import IconShadow from '../../../../assets/marker-shadow.png';
import ImageComponent from '../../../../components/Image';
import L from 'leaflet';
import MarkerCompleted from '../../../../assets/marker-icon-complete.png';
import MarkerInProgress from '../../../../assets/marker-icon-in-progress.png';
import MarkerNoAccess from '../../../../assets/marker-icon-no-access.png';
import MarkerNotStarted from '../../../../assets/marker-icon.png';
import { colours } from '../../../../config/theme';

const ROOM_IMAGE_SIZE = 200;
const NO_IMAGE_SIZE = 160;

const ImageMap = ({ url, width, height, onDrag, onImageLoaded }) => {
  const MAP_ID = 'MAP_ID';

  const map = useMapEvents({
    drag() {
      onDrag?.();
    },
    click() {
      onDrag?.();
    },
  });

  return useMemo(() => {
    const southWest = map.unproject([0, height], map.getMaxZoom() - 1);
    const northEast = map.unproject([width, 0], map.getMaxZoom() - 1);
    const bounds = new L.LatLngBounds(southWest, northEast);
    map.options.maxBoundsViscosity = 0.9;
    const image = L.imageOverlay(url, bounds);

    image.on(
      'load',
      ({
        target: {
          _image: { clientWidth, clientHeight },
        },
      }) => {
        // have to check there is actually a width and height as
        // this load event fires multiple times and initial values are 0
        if (clientWidth && clientHeight) {
          onImageLoaded(clientWidth, clientHeight);
        }
      },
    );

    image.id = MAP_ID;
    map.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    image.addTo(map);
    map.setZoom(0);
    map.setMaxBounds(bounds);
    return null;
  }, [url, width, height, map]);
};

const getImageDimensions = async (fileUrl) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => {
      const { naturalWidth, naturalHeight } = image;
      resolve({ width: naturalWidth, height: naturalHeight });
    });
    image.src = fileUrl;
  });
};

function getIconFromStatus(status) {
  let iconFile;

  switch (status) {
    case statuses.NOT_STARTED:
      iconFile = MarkerNotStarted;
      break;
    case statuses.IN_PROGRESS:
      iconFile = MarkerInProgress;
      break;
    case statuses.COMPLETED:
      iconFile = MarkerCompleted;
      break;
    case statuses.NO_ACCESS:
      iconFile = MarkerNoAccess;
      break;
    default:
      iconFile = MarkerNotStarted;
  }
  return L.icon({
    iconUrl: iconFile,
    shadowUrl: IconShadow,
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
  });
}

function Markers({ markers, onClickMarker, ratios }) {
  const map = useMap();

  const markerClicked = ({ latlng, roomId }) => {
    map.panTo(latlng);
    onClickMarker(roomId);
  };

  const addMarker = ({
    lat,
    lng,
    x,
    y,
    id,
    roomId,
    status = statuses.NOT_STARTED,
  }) => {
    // we have to convert the x,y pixel position from the map into a map
    // co-ordinate to take into account any markers that were added in the
    // app as that does not use leaflet so the lat,lng on that room will not exist yet
    const convertedFromXY = map.unproject([x / ratios.x, y / ratios.y]);
    const latLng = L.latLng(convertedFromXY.lat, convertedFromXY.lng);

    const marker = L.marker(latLng, {
      icon: getIconFromStatus(status),
    });
    marker.id = id;
    marker.addTo(map);
    marker.on('click', ({ latlng }) => markerClicked({ latlng, roomId }));
  };
  useEffect(() => {
    if (!map || !markers?.length) {
      return;
    }
    markers.forEach((marker) => addMarker(marker));
  }, [markers, map]);
  return null;
}

export default function ViewFloorPlanDialog({
  onClose,
  open,
  floorPlan,
  roomsList,
}) {
  const { url, markers } = floorPlan;
  const history = useHistory();
  const location = useLocation();
  const [currentRoom, setCurrentRoom] = useState();
  const [origImageDimensions, setOrigImageDimensions] = useState(null);
  const [currentImageDimensions, setCurrentImageDimensions] = useState();

  const ratios = useMemo(() => {
    if (!currentImageDimensions || !origImageDimensions) {
      return null;
    }
    return {
      x: origImageDimensions.width / currentImageDimensions.width,
      y: origImageDimensions.height / currentImageDimensions.height,
    };
  }, [currentImageDimensions, origImageDimensions]);

  const markersWithStatus = useMemo(() => {
    return (
      markers
        .map((m) => {
          const roomForMarker = roomsList?.find((r) => r.id === m.roomId);
          // it wont be in the list if the room has been deleted, therefore return undefined and clean up later
          if (!roomForMarker) {
            return undefined;
          }
          return {
            ...m,
            status: roomForMarker?.status || statuses.NOT_STARTED,
          };
        })
        // remove any undefined values
        .filter((r) => r)
    );
  }, [markers, roomsList]);

  useEffect(() => {
    if (url) {
      const getDims = async () => {
        const { width, height } = await getImageDimensions(url);
        setOrigImageDimensions({ width, height });
      };
      getDims();
    }
  }, [url]);

  const onClickClose = () => {
    setCurrentRoom(undefined);
    onClose?.();
  };

  const onDrag = () => {
    setCurrentRoom(undefined);
  };

  const onSelectRoom = (roomId) => {
    const room = roomsList.find((r) => r.id === roomId);
    setCurrentRoom(room);
  };

  const currentRoomImage = useMemo(() => {
    return currentRoom?.images?.[0] || undefined;
  }, [currentRoom]);

  const onClickGoToRoom = () => {
    history.push(`${location.pathname}/${currentRoom.id}`);
  };

  const onImageLoaded = (clientWidth, clientHeight) => {
    setCurrentImageDimensions({ width: clientWidth, height: clientHeight });
  };

  return (
    <Dialog open={!!open} onClose={onClickClose} fullScreen>
      <DialogTitle id="scroll-dialog-title">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Floor Plan - {floorPlan.floor}</Typography>
          <Stack direction="row" spacing={2}>
            {/* <Button variant="outlined" onClick={() => {}} color="primary">
              Export Image
            </Button> */}

            <IconButton
              onClick={onClickClose}
              aria-label="close"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <MapContainer
          minZoom={1}
          maxZoom={4}
          center={[0, 0]}
          zoom={1}
          crs={L.CRS.Simple}
          doubleClickZoom={false}
          maxBoundsViscosity={0.9}
          bounceAtZoomLimits={false}
          attributionControl={false}
          style={{ height: '100%', width: '100%', zIndex: 1 }}
        >
          {origImageDimensions ? (
            <ImageMap
              url={url}
              width={origImageDimensions.width}
              height={origImageDimensions.height}
              onDrag={onDrag}
              onImageLoaded={onImageLoaded}
            />
          ) : null}
          {ratios ? (
            <Markers
              markers={markersWithStatus}
              onClickMarker={onSelectRoom}
              ratios={ratios}
            />
          ) : null}
        </MapContainer>

        {currentRoom ? (
          <Box
            backgroundColor="red"
            position="absolute"
            width="100%"
            height={currentRoomImage ? ROOM_IMAGE_SIZE * 1.5 : NO_IMAGE_SIZE}
            bottom={80}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              height={currentRoomImage ? ROOM_IMAGE_SIZE * 1.5 : NO_IMAGE_SIZE}
              position="absolute"
              backgroundColor={colours.light2}
              borderRadius={2}
              zIndex={100}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-evenly"
              minWidth={currentRoomImage ? 500 : 400}
              maxWidth={800}
              p={3}
              boxShadow={3}
            >
              <Box position="absolute" top={2} right={2}>
                <IconButton
                  onClick={onDrag}
                  aria-label="close"
                  sx={{
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              {currentRoomImage ? (
                <Box display="flex" mr={3}>
                  <ImageComponent
                    alt={`${currentRoom.name} image`}
                    src={currentRoomImage?.uri}
                    style={{ width: ROOM_IMAGE_SIZE, height: ROOM_IMAGE_SIZE }}
                  />
                </Box>
              ) : null}
              <Stack
                direction={currentRoomImage ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems={currentRoomImage ? 'flex-start' : 'center'}
                spacing={2}
              >
                <Stack>
                  <Typography>{currentRoom.name}</Typography>
                  <Typography variant="caption">
                    Status :{' '}
                    <Typography
                      variant="caption"
                      sx={{ color: getStatusColour(currentRoom.status) }}
                    >
                      {statusToString(currentRoom.status)}
                    </Typography>
                  </Typography>
                  <Typography variant="caption">
                    Floor area (m2): {currentRoom.areaFloor.toFixed(2)}
                  </Typography>
                  <Typography variant="caption">
                    Wall area (m2): {currentRoom.areaWall.toFixed(2)}
                  </Typography>
                  <Typography variant="caption">
                    Assets Collected:{' '}
                    {currentRoom.assetsNumCreated -
                      currentRoom.assetsNumDeleted}
                  </Typography>
                  <Typography variant="caption">
                    Partials Collected:{' '}
                    {currentRoom.partialsNumCreated -
                      currentRoom.partialsNumDeleted}
                  </Typography>
                </Stack>
                <Button
                  disabled={
                    currentRoom.assetsNumCreated -
                      currentRoom.assetsNumDeleted ===
                    0
                  }
                  variant="outlined"
                  size="small"
                  onClick={onClickGoToRoom}
                >
                  Go To Room
                </Button>
              </Stack>
            </Box>
          </Box>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
