import { shortenURL } from '../shared/utilities';

const URL_TO_SHORTEN =
  'https://firebasestorage.googleapis.com/v0/b/asseticom-image-room/o/rlbnhsps%2FFslBoA3MVzYhleaCorWM%2FyofbkXcQkOCJanKEmuEX%2FswK5rmRJc7IARddUfKZfB2LaM%2Fqkyg2125osw0zzt1rfdykk36.jpg?alt=media&token=50add7d8-b87d-47b9-86c2-2c914d8353f8';

export const getShortUrl = async () => {
  try {
    const shortURL = await shortenURL(URL_TO_SHORTEN);
    console.log('shortened url', shortURL);
  } catch (error) {
    console.error(error);
  }
};
