import { functions, useAppDispatch } from '../config/store';

import { getLastUpdated } from '../shared/logTools';
import { isViewingProdServer } from '../shared/utilities';
import { setSnackMessage } from './ui';

export const onAssetDataPreviewSubmitted =
  ({ data, maxLifeLeft }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId } = getState().firestore.data.client;
      const assetData = await functions.httpsCallable('getQueryPreview')({
        ...data,
        maxLifeLeft,
        clientId,
      });

      return assetData;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onAssetDataExportSubmitted =
  ({ data, profile }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();
      const { clientId } = getState().firestore.data.client;

      const reportData = { ...data, created: Date.now() };

      const exportDoc = await firestore
        .collection('clients')
        .doc(clientId)
        .collection('exports')
        .doc();

      exportDoc.set({ ...reportData, ...getLastUpdated() });

      const queryJobData = await functions.httpsCallable('createQueryJob')({
        ...data,
        clientId,
        profile,
        exportId: exportDoc.id,
        env: isViewingProdServer() ? 'production' : 'staging',
      });

      return queryJobData;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onPortalLevelAssetDataExportSubmitted =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId } = getState().firestore.data.client;
      const profile = getState().firebase.profile;

      const queryJobData = await functions.httpsCallable(
        'createPortalLevelAssetReport',
      )({
        clientId,
        profile,
        env: isViewingProdServer() ? 'production' : 'staging',
      });

      dispatch(
        setSnackMessage({
          message: 'Generating report...please check your email...',
        }),
      );

      return queryJobData;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export const onPortalLevelRoomDataExportSubmitted =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const { clientId } = getState().firestore.data.client;
      const profile = getState().firebase.profile;

      const queryJobData = await functions.httpsCallable(
        'createPortalLevelRoomReport',
      )({
        clientId,
        profile,
        env: isViewingProdServer() ? 'production' : 'staging',
      });

      dispatch(
        setSnackMessage({
          message: 'Generating report...please check your email...',
        }),
      );

      return queryJobData;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

export default {
  onAssetDataPreviewSubmitted,
  onPortalLevelAssetDataExportSubmitted,
  onPortalLevelRoomDataExportSubmitted,
  onAssetDataExportSubmitted,
};
