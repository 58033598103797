import { AppState, useAppDispatch } from '../../../../../config/store';
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link as MuiLink,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../../shared/helpers';
import { Link, useParams } from 'react-router-dom';
import {
  setFormData,
  useOverviewStepData,
} from '../../../../../state/survey-form';
import { useEffect, useMemo } from 'react';

import { ALL_ROLES } from '../../../../../config/roles';
import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import { DatePicker } from '@mui/x-date-pickers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ErrorText from '../../../../../components/Form/ErrorText';
import { NAV_ROUTES_MAP } from '../../../../../config/routes';
import NextButton from '../../../../../components/Form/NextButton';
import { Statuses } from '../../../../../types/status';
import { StepperStepProps } from '../../../../../types/form';
import { UrlParams } from '../../../../../types/urlParams';
import dayjs from 'dayjs';
import { useFirestoreConnect } from 'react-redux-firebase';
import useLeaveHandler from '../../hooks/useLeaveHandler';
import { useSelector } from 'react-redux';
import useSurvey from '../../../../../hooks/useSurvey';

const defaultValues = {
  name: '',
  siteName: '',
  siteId: '',
  buildingName: '',
  buildingId: '',
  gifa: '',
  external: '',
  surveyorId: '',
  surveyorName: '',
  surveyorEmail: '',
  surveyType: 'M&E',
  estimatedStartDate: '',
  estimatedDuration: '',
};
const ESTIMATED_DAYS_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

const EIGHT_HOURS_IN_MILLISECONDS = 28800000;

const Overview = ({ onSubmit, onLeave }: StepperStepProps) => {
  const overviewData = useOverviewStepData();

  const originalSurveyData = useSurvey();

  const { clientId } = useParams<UrlParams>();

  const dispatch = useAppDispatch();

  const { handleSubmit, reset, watch, control, setValue, getValues, setError } =
    useForm({
      defaultValues,
    });

  const { errors } = useFormState({
    control,
  });

  useLeaveHandler(onLeave, getValues);

  const siteIdWatcher = watch('siteId');
  const buildingIdWatcher = watch('buildingId');
  const surveyorIdWatcher = watch('surveyorId');
  const estimatedStartDateWatcher = watch('estimatedStartDate');

  const statusIsNotStarted = overviewData.status === Statuses.NOT_STARTED;

  const connectArray = [
    {
      collection: 'roles',
      where: [
        ['clientId', '==', clientId],
        ['role', 'in', [ALL_ROLES.SURVEYOR, ALL_ROLES.LEAD_SURVEYOR]],
        ['deleted', '==', 0],
      ],
      storeAs: 'surveyors',
    },
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [{ collection: 'sites' }],
      where: ['deleted', '==', 0],
      storeAs: 'sites',
      orderBy: ['created', 'desc'],
    },
  ];

  if (siteIdWatcher) {
    connectArray.push({
      collection: 'clients',
      doc: clientId,
      subcollections: [
        {
          collection: 'sites',
          // @ts-ignore
          doc: siteIdWatcher,
        },
        {
          collection: 'buildings',
          // @ts-ignore
          where: [['deleted', '==', 0]],
          orderBy: [['name', 'asc']],
        },
      ],
      storeAs: 'buildings',
    });
  }

  // @ts-ignore
  useFirestoreConnect(connectArray);

  const sites = useSelector(
    (state: AppState) => state.firestore.ordered.sites || EMPTY_ARRAY,
  );
  const buildings = useSelector(
    (state: AppState) => state.firestore.ordered.buildings || EMPTY_ARRAY,
  );
  const surveyors = useSelector(
    (state: AppState) => state.firestore.ordered.surveyors || EMPTY_ARRAY,
  );
  const client = useSelector(
    (state: AppState) => state.firestore.data.client || EMPTY_OBJECT,
  );

  useEffect(() => {
    //@ts-ignore
    reset({ ...defaultValues, ...overviewData }, { keepErrors: true });
  }, [overviewData]);

  const siteDefault = useMemo(() => {
    return sites?.find((s) => s.id === siteIdWatcher) || null;
  }, [sites, siteIdWatcher]);

  console.log('siteDefault', siteDefault);

  const buildingDefault = useMemo(() => {
    return buildings?.find((s) => s.id === buildingIdWatcher) || null;
  }, [buildings, buildingIdWatcher]);

  const surveyorDefault = useMemo(() => {
    return surveyors?.find((s) => s.uid === surveyorIdWatcher) || null;
  }, [surveyors, surveyorIdWatcher]);

  const beforeSubmit = (data) => {
    const buildingIds = buildings.map((building) => building.id);
    if (buildingIds.includes(buildingIdWatcher) === false) {
      return setError('buildingId', {
        type: 'manual',
        message: 'Building is required',
      });
    }
    if (overviewData.buildingId !== data.buildingId) {
      dispatch(setFormData({ roomSchedule: null, floorPlans: [] }));
    }
    onSubmit();
  };

  // //these two are here to populate the site and building name so its available in the room schedule export if used

  useEffect(() => {
    if (buildingDefault) {
      setValue('buildingName', buildingDefault.name);
    }
  }, [buildingDefault]);

  useEffect(() => {
    if (siteDefault) {
      setValue('siteName', siteDefault.name);
    }
  }, [siteDefault]);

  useEffect(() => {
    if (surveyorDefault) {
      setValue(
        'surveyorName',
        `${surveyorDefault.nameFirst} ${surveyorDefault.nameLast}`,
      );
      setValue('surveyorEmail', surveyorDefault.email);
    }
  }, [surveyorDefault]);

  if (!sites.length) {
    return (
      <Box p={2}>
        <Typography variant="caption" color="secondary">
          You must{' '}
          <MuiLink
            to={`/${clientId}${NAV_ROUTES_MAP.SITES_ADD.path}`}
            color="primary"
            underline="always"
            component={Link}
          >
            add at least one site{' '}
          </MuiLink>
          before you can add a survey
        </Typography>
      </Box>
    );
  }

  if (client.assetListsNumCreated - client.assetListsNumDeleted < 1) {
    return (
      <Box p={2}>
        <Typography variant="caption" color="secondary">
          You must{' '}
          <MuiLink
            to={`/${clientId}${NAV_ROUTES_MAP.ASSET_LISTS_ADD.path}`}
            color="primary"
            underline="always"
            component={Link}
          >
            add at least one asset taxonomy
          </MuiLink>{' '}
          before you can add a survey
        </Typography>
      </Box>
    );
  }

  return (
    <Box p={2}>
      <ErrorBoundary>
        <form onSubmit={handleSubmit(beforeSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    onChange={field.onChange}
                    autoComplete="off"
                    fullWidth
                    label="Survey Name"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={!!errors.name && 'Survey name is required'}
                  />
                )}
                control={control}
                name="name"
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth error={!!errors.siteId}>
                <Controller
                  control={control}
                  name="siteId"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      disabled={originalSurveyData.assetsNumCreated > 0}
                      onChange={(e, data) => {
                        field.onChange(data?.id);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.name}
                          </li>
                        );
                      }}
                      options={sites}
                      getOptionLabel={(site) => site.name}
                      value={siteDefault}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === siteDefault.id;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Site"
                          variant="outlined"
                          error={!!errors.siteId}
                          helperText={!!errors.siteId && 'Site is required'}
                          inputRef={field.ref}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.buildingId}
                className=".MuiFormControl-marginNormal"
              >
                <Controller
                  control={control}
                  name="buildingId"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Autocomplete
                      onChange={(e, data) => {
                        field.onChange(data?.id);
                      }}
                      id="select-building"
                      options={buildings}
                      value={buildingDefault}
                      isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                      }}
                      disabled={
                        originalSurveyData.assetsNumCreated > 0 ||
                        (!errors.buildingId && !buildings.length) ||
                        !siteIdWatcher
                      }
                      getOptionLabel={(building) => building.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Building"
                          variant="outlined"
                          autoComplete="off"
                          error={!!errors.buildingId}
                          helperText={
                            !!errors.buildingId && 'Building is required'
                          }
                          inputRef={field.ref}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="gifa"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    value={field.value}
                    type="number"
                    label="GIFA"
                    variant="outlined"
                    autoComplete="off"
                    onChange={(event) => {
                      return field.onChange(+event.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m2</InputAdornment>
                      ),
                    }}
                    error={!!errors.gifa}
                    helperText={!!errors.gifa && 'GIFA is required'}
                    inputRef={field.ref}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="external"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">m2</InputAdornment>
                      ),
                    }}
                    value={field.value}
                    autoComplete="off"
                    type="number"
                    label="External"
                    variant="outlined"
                    onChange={(event) => {
                      return field.onChange(+event.target.value);
                    }}
                    error={!!errors.external}
                    helperText={!!errors.external && 'External is required'}
                    inputRef={field.ref}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.estimatedStartDate}
                className=".MuiFormControl-marginNormal"
              >
                <Controller
                  control={control}
                  name="estimatedStartDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <DatePicker
                      minDate={dayjs(Date.now())}
                      value={dayjs(estimatedStartDateWatcher || '') || ''}
                      onChange={(value) => {
                        field.onChange(
                          // set it to 8am to avoid timezone issues and also as surveys are usually done in the morning
                          dayjs(value).valueOf() + EIGHT_HOURS_IN_MILLISECONDS,
                        );
                      }}
                      label="Estimated Start Date"
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          inputRef: field.ref,
                          error: !!errors.estimatedStartDate,
                          helperText: errors.estimatedStartDate
                            ? 'Estimated Start Date is required'
                            : 'MM/DD/YYYY',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.estimatedDuration}
                className=".MuiFormControl-marginNormal"
              >
                <InputLabel htmlFor="estimatedDuration">
                  Estimated Days to Complete
                </InputLabel>
                <Controller
                  control={control}
                  name="estimatedDuration"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => (
                    <Select
                      fullWidth
                      variant="outlined"
                      label="Estimated Days to Complete"
                      native
                      value={field.value}
                      inputRef={field.ref}
                      error={!!errors.estimatedDuration}
                      onChange={(event) => {
                        return field.onChange(+event.target.value);
                      }}
                    >
                      <option key={0} value="" aria-label="empty" />
                      {ESTIMATED_DAYS_OPTIONS.map((numDays) => (
                        <option key={numDays} value={numDays}>
                          {numDays}
                        </option>
                      ))}
                    </Select>
                  )}
                ></Controller>
                {errors.estimatedDuration && (
                  <FormHelperText>
                    Estimated Days to Complete is required
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.surveyorId}
              >
                {surveyors.length ? (
                  <Controller
                    control={control}
                    name="surveyorId"
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Autocomplete
                        onChange={(e, data) => {
                          field.onChange(data?.uid);
                        }}
                        id="select-surveyor"
                        options={surveyors}
                        getOptionLabel={(surveyor) =>
                          `${surveyor.nameFirst} ${surveyor.nameLast} - [${surveyor.email}]`
                        }
                        value={surveyorDefault}
                        isOptionEqualToValue={(option, value) =>
                          option.uid === value.uid
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Surveyor"
                            variant="outlined"
                            autoComplete="off"
                            error={!!errors.surveyorId}
                            helperText={
                              !!errors.surveyorId && 'Surveyor is required'
                            }
                            inputRef={field.ref}
                          />
                        )}
                      />
                    )}
                  />
                ) : null}
              </FormControl>
            </Grid>
          </Grid>
          <Box mt={2}>
            <FormControl
              variant="outlined"
              component="fieldset"
              error={!!errors.surveyType}
            >
              <FormLabel component="legend">Survey Type</FormLabel>
              <Controller
                rules={{ required: true }}
                name="surveyType"
                control={control}
                render={({ field }) => (
                  <RadioGroup aria-label="survey type" row {...field}>
                    <FormControlLabel
                      value="M&E"
                      control={<Radio />}
                      label="M&E"
                    />
                    <FormControlLabel
                      value="Fabric"
                      control={<Radio />}
                      label="Fabric"
                    />
                    <FormControlLabel
                      value="M&E and Fabric"
                      control={<Radio />}
                      label="M&E and Fabric"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                    <FormControlLabel
                      value="Test"
                      control={<Radio />}
                      label="Test"
                    />
                  </RadioGroup>
                )}
              />

              {errors.surveyType && (
                <FormHelperText>Survey Type is required</FormHelperText>
              )}
            </FormControl>
          </Box>
          {statusIsNotStarted ? (
            <>
              <Divider sx={{ my: 4 }} />
              <Box
                sx={{
                  border: 1,
                  borderColor: 'error.main',
                  borderRadius: 2,
                  p: 2,
                }}
              >
                <Typography color="error" paragraph my={2}>
                  Although this survey is marked as &apos;Not Started&apos;, it
                  may have already been started in offline mode. If this is the
                  case, <b>{originalSurveyData.surveyorName}</b> will need to
                  ensure they have connected to the internet and syncronised
                  their data before you proceed.
                  <br />
                  <br />
                  They can do this by navigating to the survey within the
                  Asseticom app and checking whether it shows as {''}
                  <b>&apos;Synced To Cloud&apos;</b>.
                  <br />
                  <br />
                  <b>
                    Proceeding to update this survey without checking may result
                    in data loss.
                  </b>
                  <br />
                  <br />
                  Should you need help with this, please{' '}
                  <MuiLink
                    color="secondary"
                    href="mailto:support@asseticom.co.uk"
                  >
                    contact us
                  </MuiLink>{' '}
                </Typography>
              </Box>
              <Divider sx={{ my: 4 }} />
            </>
          ) : null}
          <ButtonsContainer>
            <BackButton disabled hidden />
            <NextButton />
          </ButtonsContainer>
        </form>
      </ErrorBoundary>
    </Box>
  );
};

export default Overview;
