// import * as DELETED_JSON from '../../deleted.json';

import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { dateFromTimeStamp, timeFromTimeStamp } from '../../shared/utilities';

import CardGrid from '../../components/CardGrid';
import ClientCard from './ClientCard';
import ContextAttribute from '../../components/ContextAttribute';
import DebugOnly from '../../components/DebugOnly';
import DynamicIcon from '../../components/DynamicIcon';
import { EMPTY_ARRAY } from '../../shared/helpers';
import ErrorBoundary from '../../components/ErrorBoundary';
import Paginator from '../../components/Paginator';
import Table from '../../components/Table';
import TextureButton from '../../components/TextureButton';
import { getShortUrl } from '../../admin-functions/getShortUrl';
import { makeStyles } from '@mui/styles';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
  },
  textBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  welcome: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 24,
  },
}));

const asseticomButtons = [
  {
    icon: 'rosette',
    colour: 'green',
    text: 'ADD NEW',
    textBold: 'CLIENT PORTAL',
    linkTo: '/clients/add',
  },
  {
    icon: 'users',
    colour: 'blue',
    text: 'ADD NEW',
    textBold: 'ASSETICOM USER',
    linkTo: '/users/add',
  },
];

const columnsForLastXAssets = [
  {
    accessorKey: 'client',
    header: 'Portal',
  },
  { accessorKey: 'survey', header: 'Survey' },

  {
    accessorKey: 'surveyor',
    header: 'Surveyor',
  },
  { accessorKey: 'time', header: 'Last Updated' },
  {
    accessorKey: 'device',
    header: 'Device',
  },
  {
    accessorKey: 'version',
    header: 'App Version',
  },
];

function convertLastXToRows(data) {
  return (
    data?.map((doc) => {
      const time = doc.lastUpdated.time;
      return {
        client: doc.clientId,
        survey: doc.surveyName,
        surveyor: doc.surveyorName,
        time: `${dateFromTimeStamp(time)} ${timeFromTimeStamp(time)}`,
        device:
          `${doc.lastUpdated.manufacturer} ${doc.lastUpdated.deviceName}` ||
          'Unknown',
        version:
          `Version ${doc.lastUpdated.release} Build ${doc.lastUpdated.dist}` ||
          'Unknown',
      };
    }) || []
  );
}

const LAST_X_ASSETS_DEFAULT = 5;

const Dashboard = () => {
  const classes = useStyles();

  const [clientsPerPage, setClientsPerPage] = useState(30);
  const [lastXToShow, setLastXToShow] = useState(LAST_X_ASSETS_DEFAULT);
  const [cursor, setCursor] = useState(0);

  const clients = useSelector(
    (state) => state.firestore.ordered.clients || EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'clients',
      storeAs: 'clients',
      limit: clientsPerPage,
      startAt: cursor,
      orderBy: ['created', 'desc'],
      where: ['deleted', '==', 0],
    },
  ]);

  const stats = useSelector(
    (state) => state.firestore.data.stats || EMPTY_ARRAY,
  );

  const lastXUpdatedAssets = useSelector(
    (state) =>
      convertLastXToRows(state.firestore.ordered.lastXUpdatedAssets) ||
      EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'globals',
      doc: 'stats',

      storeAs: 'stats',
    },
    {
      collectionGroup: 'assets',
      orderBy: ['lastUpdated.time', 'desc'],
      limit: lastXToShow,
      storeAs: 'lastXUpdatedAssets',
    },
  ]);

  return (
    <Grid container spacing={2} pt={2}>
      <DebugOnly>
        <Button
          onClick={() => {
            getShortUrl();
          }}
        >
          Run Admin Function
        </Button>
      </DebugOnly>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        {asseticomButtons.map((config) => {
          const { colour, icon, text, textBold, linkTo } = config;
          return (
            <Grid key={linkTo} item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextureButton colour={colour} linkTo={linkTo}>
                <DynamicIcon name={icon} height="25" colour={colour} inCircle />
                <Box
                  marginTop={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Typography className={classes.text} align="center">
                    {text}
                  </Typography>
                  <Typography
                    className={`${classes.text} ${classes.textBold}`}
                    align="center"
                  >
                    {textBold}
                  </Typography>
                </Box>
              </TextureButton>
            </Grid>
          );
        })}
      </Grid>
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.clientsNumCreated || 0}
                type="Active Portals"
              />
              <ContextAttribute
                value={stats.clientsNumDeleted || 0}
                type="Inactive Portals"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.usersNumCreated || 0}
                type="Users Created"
              />
              <ContextAttribute
                value={stats.usersNumDeleted || 0}
                type="Users Deleted"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.assetsNumCreated || 0}
                type="Assets Collected"
              />
              <ContextAttribute
                value={stats.assetsNumDeleted || 0}
                type="Assets Deleted"
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <Box display="flex" justifyContent="space-evenly">
              <ContextAttribute
                value={stats.partialsNumCreated || 0}
                type="Partials Collected"
              />

              <ContextAttribute
                value={stats.partialsNumDeleted || 0}
                type="Partials Deleted"
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {lastXUpdatedAssets?.length ? (
        <Grid container item alignItems="flex-end" justifyContent="center">
          <Grid item xs={12}>
            <Paper>
              <Typography variant="subtitle2" px={2} pt={1}>
                Last {lastXToShow} Collected Assets
              </Typography>
              <Box p={4}>
                <Table
                  rowsPerPage={5}
                  onPerPageChange={setLastXToShow}
                  rows={lastXUpdatedAssets}
                  columns={columnsForLastXAssets}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      ) : null}
      <Grid
        container
        item
        alignItems="flex-end"
        spacing={2}
        className={classes.topRow}
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Paper>
            {/* <Box display="flex" alignItems="center" p={2}>
              <Box mr={2}>
                <TextField
                  type="search"
                  variant="outlined"
                  label="Search Client Portals"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DynamicIcon
                          name="search"
                          colour="secondary"
                          width="20px"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Button variant="contained" color="secondary">
                Search
              </Button>
            </Box> */}
            {clients.length ? (
              <Box p={2}>
                <ErrorBoundary>
                  <CardGrid
                    dataArray={clients}
                    render={(client) => <ClientCard client={client} />}
                  />
                  <Stack direction="row" justifyContent="flex-end">
                    <Paginator
                      label="Clients per page:"
                      total={stats.clientsNumCreated}
                      perPage={clientsPerPage}
                      perPageOptions={[6, 12, 18, 24, 30]}
                      onChangePage={setCursor}
                      onChangePerPage={setClientsPerPage}
                      dataArray={clients}
                      dataProp="created"
                    />
                  </Stack>
                </ErrorBoundary>
              </Box>
            ) : null}
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
