import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import { MapContainer, useMap, useMapEvents } from 'react-leaflet';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  getRandomID,
  removeDuplicatesFromArray,
} from '../../../../../../shared/utilities';

import { CompareSharp } from '@mui/icons-material';
import { EMPTY_ARRAY } from '../../../../../../shared/helpers';
import L from 'leaflet';
import PropTypes from 'prop-types';
import icon from '../../../../../../assets/marker-icon.png';
import iconShadow from '../../../../../../assets/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
});

const Markers = ({ onAddedNewMarker, onSelectedMarker, markersList }) => {
  const map = useMap();
  const [initialised, setInitialised] = useState(false);

  const selectedMarker = (latlng, id) => {
    map.panTo(latlng);
    onSelectedMarker(id);
  };

  const onClickMarker = (event) => {
    selectedMarker(event.latlng, event.target.id);
  };

  const addMarker = (latlng, id) => {
    const marker = L.marker(latlng, { icon: DefaultIcon });
    marker.id = id;
    marker.addTo(map);
    selectedMarker(latlng, id);
    marker.on('click', onClickMarker);
  };

  useMapEvents({
    dblclick(event) {
      const { latlng } = event;
      const id = getRandomID();
      addMarker(latlng, id);
      onAddedNewMarker(latlng, id);
    },
  });

  // populate floorplan with pre-existing marker data
  useEffect(() => {
    if (map && markersList?.length && !initialised) {
      markersList.forEach((marker) => {
        const { lat, lng, id } = marker;
        addMarker(L.latLng(lat, lng), id);
      });
      setInitialised(true);
    }
  }, [markersList, initialised, map]);

  return null;
};

const ImageMap = React.forwardRef(({ url, width, height }, ref) => {
  const map = useMap();

  const MAP_ID = 'MAP_ID';

  return useMemo(() => {
    const southWest = map.unproject([0, height], map.getMaxZoom() - 1);
    const northEast = map.unproject([width, 0], map.getMaxZoom() - 1);
    const bounds = new L.LatLngBounds(southWest, northEast);
    map.options.maxBoundsViscosity = 0.9;
    const image = L.imageOverlay(url, bounds);
    image.id = MAP_ID;
    ref.current = image;
    map.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    image.addTo(map);
    map.setZoom(0);
    map.setMaxBounds(bounds);
    return null;
  }, [url]);
});

ImageMap.displayName = 'ImageMap';

const getImageDimensions = async (fileUrl) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener('load', () => {
      const { naturalWidth, naturalHeight } = image;
      resolve({ width: naturalWidth, height: naturalHeight });
    });
    image.src = fileUrl;
  });
};

const MapElement = ({
  fileUrl,
  onMarkersChanged,
  onImageLoaded,
  roomsList,
  markersList,
}) => {
  const [map, setMap] = useState(null);
  const imageOverlayRef = useRef();
  const [markers, setMarkers] = useState(markersList || []);
  const [selectedMarkerId, setSelectedMarkerId] = useState(null);
  const [currentMarker, setCurrentMarker] = useState({});
  const [dimensions, setDimensions] = useState(null);
  const [duplicateRoomsNum, setDuplicateRoomsNum] = useState(0);
  const [uniqueAssignedRoomsNum, setUniqueAssignedRoomsNum] =
    useState(EMPTY_ARRAY);

  useEffect(() => {
    if (fileUrl) {
      const getDims = async () => {
        const { width, height } = await getImageDimensions(fileUrl);
        setDimensions({ width, height });
        onImageLoaded({ width, height });
      };
      getDims();
    }
  }, [fileUrl]);

  const addedNewMarker = (latlng, id) => {
    const currentFloorplanDimensions = {
      width: imageOverlayRef?.current?._image.clientWidth,
      height: imageOverlayRef?.current?._image.clientHeight,
    };
    const ratios = {
      x: dimensions.width / currentFloorplanDimensions.width,
      y: dimensions.height / currentFloorplanDimensions.height,
    };
    const pixelPoint = map.project(latlng, map.getZoom());
    const xy = {
      x: pixelPoint.x * ratios.x,
      y: pixelPoint.y * ratios.y,
    };

    const newMarkers = [...markers, { ...latlng, id, ...xy }];
    setMarkers(newMarkers);
  };

  const selectedMarker = (selectedId) => {
    setSelectedMarkerId(selectedId);
  };

  const deleteMarker = () => {
    map.eachLayer((layer) => {
      if (layer.id === currentMarker.id) {
        return map.removeLayer(layer);
      }
      return null;
    });
    const removedMarkers = markers.filter((marker) => {
      return marker.id !== currentMarker.id;
    });
    setCurrentMarker({});
    setMarkers(removedMarkers);
  };

  useEffect(() => {
    const assigned = markers.reduce((accumulator, marker) => {
      if (marker.roomId) {
        accumulator.push(marker.roomId);
      }
      return accumulator;
    }, []);
    const unique = removeDuplicatesFromArray(assigned);
    setUniqueAssignedRoomsNum(unique.length);
    setDuplicateRoomsNum(assigned.length - unique.length);
    const matchedMarker = markers.find((marker) => {
      return marker.id === selectedMarkerId;
    });
    if (matchedMarker) {
      setCurrentMarker(matchedMarker);
    }

    onMarkersChanged(markers, dimensions);
  }, [selectedMarkerId, markers]);

  const selectedRoom = (event) => {
    const { value } = event.target;
    const markerIndex = markers.findIndex((marker) => {
      return marker.id === selectedMarkerId;
    });
    const updatedMarker = { ...markers[markerIndex], roomId: value };
    const updatedMarkers = markers.map((marker, index) => {
      return index === markerIndex ? updatedMarker : marker;
    });
    setMarkers(updatedMarkers);
  };

  const markerRoomIds = useMemo(() => {
    return markersList.map((m) => m.roomId);
  }, [markersList]);

  const selectedRoomId = useMemo(() => {
    return markersList.find((m) => m.id === currentMarker.id)?.roomId;
  }, [markersList, currentMarker]);

  const roomsListParsed = useMemo(() => {
    return roomsList.filter((r) => {
      return markerRoomIds.indexOf(r.id) === -1 || r.id === selectedRoomId;
    });
  }, [roomsList, markerRoomIds, selectedRoomId]);

  return (
    <>
      <MapContainer
        minZoom={1}
        maxZoom={4}
        center={[0, 0]}
        zoom={1}
        crs={L.CRS.Simple}
        doubleClickZoom={false}
        maxBoundsViscosity={0.9}
        bounceAtZoomLimits={false}
        attributionControl={false}
        whenCreated={setMap}
        style={{ height: '400px', width: '100%' }}
      >
        {dimensions && (
          <ImageMap
            ref={imageOverlayRef}
            url={fileUrl}
            width={dimensions.width}
            height={dimensions.height}
          />
        )}

        {dimensions && (
          <Markers
            onAddedNewMarker={addedNewMarker}
            onSelectedMarker={selectedMarker}
            markersList={markers}
          />
        )}
      </MapContainer>
      <Box mt={2}>
        <Grid
          item
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography align="center" color="primary">
              {uniqueAssignedRoomsNum} of {roomsList.length} rooms assigned
            </Typography>
            {duplicateRoomsNum ? (
              <Typography align="center" color="error">
                {duplicateRoomsNum} duplicate room
                {duplicateRoomsNum === 1 ? '' : 's'} assigned!
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              disabled={!currentMarker.id}
            >
              <InputLabel htmlFor="room">Assign Room to Marker</InputLabel>
              <Select
                variant="outlined"
                label="Assign Room to Marker"
                onChange={selectedRoom}
                value={currentMarker.roomId || 0}
                native
                inputProps={{
                  name: 'roomId',
                  id: 'room',
                }}
              >
                <option key={0} value="" aria-label="empty" />
                {roomsListParsed.map((room) => (
                  <option key={room.id} value={room.id}>
                    {room.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            container
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Button
              disabled={!currentMarker.id}
              color="secondary"
              variant="contained"
              onClick={deleteMarker}
              size="small"
            >
              Remove Marker
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

MapElement.defaultProps = {
  markersList: null,
};

MapElement.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  onMarkersChanged: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  roomsList: PropTypes.arrayOf(PropTypes.any).isRequired,
  markersList: PropTypes.arrayOf(PropTypes.any),
};
ImageMap.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default MapElement;
