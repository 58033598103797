import { BIG_ASSET_LIST_NUM_ROWS, PATHS } from '../config/constants';
import {
  fileNameAndExtensionFromPath,
  getBlobFile,
  getStorageForPath,
} from '../shared/utilities';
import { getLastUpdated, recordError } from '../shared/logTools';
import { gzipSync, strToU8 } from 'fflate';

import { AssetAttributes } from '../types/asset';
import { setSnackMessage } from './ui';

type AssetListSubmitProps = {
  name: string;
  fileName: string;
  assetList: AssetAttributes[];
};

function assetListAsJSON(assetList: AssetAttributes[]) {
  return JSON.stringify(assetList);
}

export const onNewAssetListSubmitted =
  ({ name, fileName, assetList }: AssetListSubmitProps) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      let xlsxUrl =
        'https://firebasestorage.googleapis.com/v0/b/asseticom-uk.appspot.com/o/testing%2FTrial-TAXONOMY-hierarchy-matrix-new-model.xlsx?alt=media&token=dc1fdf1a-c543-432e-87bb-ca3962752747';
      let jsonUrl = '';
      const firestore = getFirebase().firestore();
      const { files } = getState();
      const { client } = getState().firestore.data;
      const { clientId } = client;

      const timestamp = Date.now();

      const assetListStorage = getStorageForPath(PATHS.STORAGE.TAXONOMY);

      const xlsxPath = `${clientId}/${fileName}`.replace(
        '.xlsx',
        `-${timestamp}.xlsx`,
      );
      const xlsxRef = assetListStorage.ref(xlsxPath);

      const jsonName = `${clientId}/${name}-${timestamp}.json.gzip`;

      const jsonPath = `${clientId}/${jsonName}`;
      const jsonRef = assetListStorage.ref(jsonPath);

      const { fileUrl } = files.assetList;
      try {
        const file = await getBlobFile(fileUrl);
        await xlsxRef.put(file);
        xlsxUrl = await xlsxRef.getDownloadURL();

        const jsonString = assetListAsJSON(assetList);

        const gzipped = gzipSync(strToU8(jsonString), {
          filename: `${name}.json`,
        });
        await jsonRef.put(gzipped, {
          cacheControl: 'public,max-age=4000',
        });
        jsonUrl = await jsonRef.getDownloadURL();
      } catch (error) {
        recordError(error);
        return window.alert(
          'There was an error uploading the asset taxonomy file, please try again.',
        );
      }

      const clientDocRef = await firestore.collection('clients').doc(clientId);
      const assetListDocRef = await clientDocRef.collection('assetLists').doc();
      await assetListDocRef.set({
        id: assetListDocRef.id,
        name,
        fileName,
        url: xlsxUrl,
        jsonUrl,
        created: Date.now(),
        deleted: 0,
        numRows: assetList.length,
        ...getLastUpdated(),
      });
      dispatch(
        setSnackMessage({
          message: 'Successfully uploaded the asset taxonomy',
        }),
      );
      return { id: assetListDocRef.id as string, error: undefined };
    } catch (error) {
      console.error(error);
      return { error, id: undefined };
    }
  };

export const onAssetListDelete =
  ({ id }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      const firestore = getFirestore();
      const { files } = getState();
      const { client } = getState().firestore.data;
      const { clientId } = client;

      const clientDocRef = await firestore.collection('clients').doc(clientId);

      const assetListDocRef = clientDocRef.collection('assetLists').doc(id);

      clientDocRef.update({
        assetListsNumDeleted: firestore.FieldValue.increment(1),
      });
      await assetListDocRef.update({ deleted: Date.now() });

      dispatch(
        setSnackMessage({ message: 'Successfully deleted the asset taxonomy' }),
      );
      return true;
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

const SFG20_COLUMNS = [
  'maintenanceCode',
  'title',
  'skillSet',
  'skillSetFull',
  'uom',
  'annualTiming',
  'hours1',
  'hours2',
  'days1',
  'days3',
  'weeks1',
  'weeks2',
  'weeks4',
  'weeks6',
  'months1',
  'months2',
  'months3',
  'months4',
  'months6',
  'months12',
  'months13',
  'months14',
  'months18',
  'months24',
  'months26',
  'months30',
  'months36',
  'months48',
  'months60',
  'months120',
  'years1',
  'years2',
  'years3',
  'years4',
  'years5',
  'years6',
  'years7',
  'years8',
  'years10',
  'years12',
  'years15',
  'years20',
  'years25',
  'users0',
];

type SFG20Row = {
  maintenanceCode: string;
  title: string;
  skillSet: string;
  skillSetFull: string;
  uom: string;
  annualTiming: number;
  hours1: number; // to convert to weekly value * 24 * 7
  hours2: number; // to convert to weekly value * 12 * 7
  days1: number; // to convert to weekly value * 7
  days3: number; // to convert to weekly value * 7 / 3
  weeks1: number;
  weeks2: number;
  weeks4: number;
  weeks6: number;
  months1: number;
  months2: number;
  months3: number;
  months4: number;
  months6: number;
  months12: number;
  months13: number;
  months14: number;
  months18: number;
  months24: number;
  months26: number;
  months30: number;
  months36: number;
  months48: number;
  months60: number;
  months120: number;
  years1: number;
  years2: number;
  years3: number;
  years4: number;
  years5: number;
  years6: number;
  years7: number;
  years8: number;
  years10: number;
  years12: number;
  years15: number;
  years20: number;
  years25: number;
  users0: number;
};

type SFG20Data = {
  rows: SFG20Row[];
  headers: string[];
  types: string[];
};

type SFG20SubmitProps = {
  name: string;
  fileName: string;
  data: SFG20Data;
};

function removeZeroValueEntries(row: SFG20Row) {
  const newRow = { ...row };
  Object.keys(newRow).forEach((key) => {
    if (newRow[key] === 0) {
      delete newRow[key];
    }
  });
  return newRow;
}

export const onSFG20Submitted =
  ({ fileName, data }: SFG20SubmitProps) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();

      const sfg20DocRef = await firestore.collection('sfg20').doc();
      const dataObj = {
        created: Date.now(),
        deleted: 0,
        fileName,
        numRows: data.rows.length,
        rows: {},
      };
      data.rows.forEach((row) => {
        //sanitise the code
        const maintenanceCode = row.maintenanceCode
          .toUpperCase()
          .replace(/[^A-Z0-9-]/g, '');
        dataObj.rows[maintenanceCode] = {
          ...removeZeroValueEntries(row),
          maintenanceCode,
        };
      });
      await sfg20DocRef.set(dataObj);
      dispatch(
        setSnackMessage({
          message: 'Successfully uploaded the SFG20 Data',
        }),
      );
      return { id: sfg20DocRef.id as string, error: undefined };
    } catch (error) {
      console.error(error);
      return { error, id: undefined };
    }
  };

export default onNewAssetListSubmitted;
