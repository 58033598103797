import * as pdfjs from 'pdfjs-dist/build/pdf';

import { Box, Grid, Typography } from '@mui/material';
import {
  setFormData,
  useFloorPlansStepData,
} from '../../../../../state/survey-form';
import { useEffect, useState } from 'react';

import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import FloorPlan from './components/FloorPlan';
import { Floorplan } from '../../../../../types/floorplan';
import NextButton from '../../../../../components/Form/NextButton';
import { StepperStepProps } from '../../../../../types/form';
import { groupArrayByProp } from '../../../../../shared/utilities';
import { useAppDispatch } from '../../../../../config/store';
import { useForm } from 'react-hook-form';
import useLeaveHandler from '../../hooks/useLeaveHandler';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function FloorPlans({ onLeave, onSubmit, onBack }: StepperStepProps) {
  const dispatch = useAppDispatch();
  const { floorPlans, roomSchedule } = useFloorPlansStepData();

  const { handleSubmit, reset, getValues } = useForm();

  const [expanded, setExpanded] = useState('');

  useLeaveHandler(onLeave, getValues);

  useEffect(() => {
    reset({ floorPlans }, { keepErrors: true });
  }, [floorPlans, reset]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : '');
  };

  const roomsByFloor = groupArrayByProp(
    roomSchedule?.data || EMPTY_ARRAY,
    'floor',
  );

  const floors = roomSchedule?.floors || Array.from(roomsByFloor.keys());

  const onUpdateFloorplan = (
    data: Partial<Floorplan> | undefined,
    floorName,
  ) => {
    if (!data) {
      //if no data was passed then should remove it from the list as it has been removed using the ui
      const floorPlansWithoutRemoved = floorPlans?.filter(
        (fp) => fp.floor !== floorName,
      );
      return dispatch(setFormData({ floorPlans: floorPlansWithoutRemoved }));
    }
    const floorPlanIndex =
      floorPlans?.findIndex((fp) => fp.floor === floorName) ?? -1;
    if (floorPlanIndex > -1) {
      const withUpdated = floorPlans?.map((fp, index) => {
        if (index === floorPlanIndex) {
          return {
            ...data,
            floor: floorName,
          };
        }
        return fp;
      });
      return dispatch(setFormData({ floorPlans: withUpdated }));
    }
    const withNewPlanAdded = floorPlans
      ? [
          ...floorPlans,
          {
            ...data,
            floor: floorName,
          },
        ]
      : [
          {
            ...data,
            floor: floorName,
          },
        ];
    dispatch(setFormData({ floorPlans: withNewPlanAdded }));
  };

  return (
    <Box p={2}>
      <ErrorBoundary>
        <form onSubmit={handleSubmit(onSubmit)}>
          {roomSchedule ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {floors.map((floor, index) => {
                  const rooms = roomsByFloor.get(floor);
                  const plan = floorPlans?.find((fp) => fp.floor === floor);

                  if (!rooms?.length) {
                    return null;
                  }
                  return (
                    <FloorPlan
                      floorPlan={plan}
                      rooms={rooms}
                      index={index}
                      expanded={expanded === `panel${index}`}
                      key={floor}
                      floorName={floor}
                      onChange={handleChange(`panel${index}`)}
                      onUpdate={onUpdateFloorplan}
                    />
                  );
                })}
              </Grid>
            </Grid>
          ) : (
            <Box py={4}>
              <Typography
                variant="caption"
                color="secondary"
                display="block"
                align="center"
              >
                You must add a room schedule to use floorplans
              </Typography>
            </Box>
          )}
          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton />
          </ButtonsContainer>
        </form>
      </ErrorBoundary>
    </Box>
  );
}

export default FloorPlans;
