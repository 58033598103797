import Compressor from 'compressorjs';
import { ImageProps } from '../types/image';
import { PATHS } from '../config/constants';
import { RoomProps } from '../types/room';
import { Statuses } from '../types/status';
import { SurveyProps } from '../types/survey';
import { getLastUpdated } from '../shared/logTools';
import { setSnackMessage } from './ui';
import { uploadImageIfNeeded } from '../shared/utilities';

export function addRequiredPropsToRoomIncaseMissing<T>(
  room: Partial<RoomProps>,
  survey: SurveyProps,
): T {
  return {
    ...room,
    areaFloor: room.areaFloor || 0,
    areaWall: room.areaWall || 0,
    assetsNumCreated: room.assetsNumCreated || 0,
    assetsNumDeleted: room.assetsNumDeleted || 0,
    assetsNumFlagged: room.assetsNumFlagged || 0,
    buildingId: survey.buildingId,
    buildingName: survey.buildingName,
    clientId: survey.clientId,
    created: room.created || Date.now(),
    deleted: room.deleted || 0,
    floor: room.floor?.trim() || '',
    images: room.images || [],
    name: room.name?.trim() || '',
    notes: (room.notes || '').trim(),
    partialsNumCreated: room.partialsNumCreated || 0,
    partialsNumDeleted: room.partialsNumDeleted || 0,
    siteId: survey.siteId,
    siteName: survey.siteName,
    status: room.status || Statuses.NOT_STARTED,
    surveyId: survey.id,
    surveyName: survey.name,
  } as T;
}

export const onSaveRoom =
  ({ room }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const { client, survey } = getState().firestore.data;
    const clientDocRef = firestore.collection('clients').doc(client.clientId);
    const surveyDocRef = clientDocRef.collection('surveys').doc(survey.id);
    if (!survey) {
      throw new Error('Survey does not exist');
    }
    // check if the floor assigned to the room is a new one, if so add it to the floors array
    const existingFloors = survey.roomSchedule?.floors || [];
    const floor = room.floor;

    const floorsUpdate =
      floor && existingFloors?.includes(floor)
        ? existingFloors
        : [...existingFloors, floor];

    const batch = firestore.batch();

    //creating a new room
    const requiredRoomData: RoomProps = addRequiredPropsToRoomIncaseMissing(
      room,
      survey,
    );

    const roomDocRef = surveyDocRef.collection('rooms').doc(room.id);

    const roomScheduleRoomData = {
      areaFloor: Number(requiredRoomData.areaFloor),
      areaWall: Number(requiredRoomData.areaWall),
      deleted: 0,
      floor: requiredRoomData.floor.trim(),
      id: roomDocRef.id,
      name: requiredRoomData.name.trim(),
      notes: requiredRoomData.notes?.trim(),
    };

    //remove the room and re-add the possibly updated version to the room schedule
    const roomScheduleDataArrayUpdated = [
      ...survey.roomSchedule.data.filter((r) => r.id !== roomDocRef.id),
      roomScheduleRoomData,
    ];

    batch.update(surveyDocRef, {
      roomsNumNotStarted: survey.roomsNumNotStarted + 1,
      'roomSchedule.data': roomScheduleDataArrayUpdated,
      'roomSchedule.floors': floorsUpdate,
      ...getLastUpdated(),
    });

    const roomData = {
      ...requiredRoomData,
      ...roomScheduleRoomData,
      ...getLastUpdated(),
      id: roomDocRef.id,
    };

    const { clientId, siteId, surveyId, id } = roomData;
    const bucketPath = PATHS.STORAGE.IMAGE_ROOM;
    const uploadPath = `/${clientId}/${siteId}/${surveyId}/${id}`;

    const imagePromises = room.images?.map((image: ImageProps) =>
      uploadImageIfNeeded({ image, bucketPath, uploadPath }),
    );

    imagePromises?.length &&
      (await Promise.all(imagePromises).then((results) => {
        roomData.images = results;
      }));

    const image360Promises = room.images360?.map((image: ImageProps) =>
      // uploadImageIfNeeded({ image, bucketPath, uploadPath, quality: 0.8 }),
      uploadImageIfNeeded({ image, bucketPath, uploadPath, quality: 0.8 }),
    );

    image360Promises?.length &&
      (await Promise.all(image360Promises).then((results) => {
        roomData.images360 = results;
      }));

    batch.set(roomDocRef, roomData, { merge: true });
    batch.commit();
    dispatch(setSnackMessage({ message: `${room.name} saved successfully` }));
  };
