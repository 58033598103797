import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from '@mui/material';
import {
  convertHeic,
  getRandomID,
  handleLocalImage,
  handlePlural,
  reduceImage,
} from '../shared/utilities';
import { useEffect, useRef, useState } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DropTarget from '../routes/client-portal/surveys/components/DropTarget';
import DropWrapper from '../routes/client-portal/surveys/components/DropWrapper';
import ImageLightBox from './ImageLightBox';
import { ImageProps } from '../types/image';
import { colours } from '../config/theme';
import isEqual from 'lodash.isequal';
import { useModal } from './Modal';

interface ImageGalleryGrid {
  onUpdate: (images: ProcessedImage[]) => void;
  images?: ImageProps[];
  imageLimit?: number;
  prompt?: string;
  resizeImages?: boolean;
}

type ImageDimensions = {
  width: number;
  height: number;
};

type ProcessedImage = ImageProps & {
  isSelected?: boolean;
};

async function getImageDimensions(url: string): Promise<ImageDimensions> {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      const height = image.height;
      const width = image.width;
      resolve({ width, height });
    };
    image.onerror = (error) => {
      console.error(error);
    };
    image.src = url;
  });
}

export default function ImageGalleryGrid({
  images = [],
  onUpdate,
  imageLimit = 5,
  prompt = 'Add an image',
  resizeImages = true,
}: ImageGalleryGrid) {
  const [currentImages, setCurrentImages] = useState<ProcessedImage[]>(images);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [processingImages, setProcessingImages] = useState(false);
  const hasSelection = currentImages?.some((i) => i.isSelected);
  const [isReplacing, setIsReplacing] = useState(false);
  const { showModal } = useModal();
  const prevImages = useRef(images);

  useEffect(() => {
    // currentImages?.length && onUpdate(currentImages);
    onUpdate?.(currentImages);
  }, [currentImages]);

  useEffect(() => {
    // horrible hack, i'm tired :( - basically if the images prop changes
    // then update current images array, stops a possible stack overflow
    if (isEqual(prevImages.current, images) === false) {
      setCurrentImages(images);
      prevImages.current = images;
    }
  }, [images]);

  async function processImageFiles(imageFiles: FileList) {
    setProcessingImages(true);
    const processedImages: ProcessedImage[] = [];

    const allowedImagesNum =
      imageLimit -
      currentImages.filter((i) => (isReplacing ? !i.isSelected : true)).length;

    // ensure dont add more than current image limit, but also
    // take into account images that may be selected for removal
    const fileListParsed = Array.from(imageFiles).slice(0, allowedImagesNum);

    if (imageFiles.length + currentImages.length > imageLimit) {
      showModal({
        title: 'Too many images',
        messages: [
          `You can only upload a maximum of ${imageLimit} images.`,
          '',
          `${handlePlural(
            imageLimit - currentImages.length + imageFiles.length,
            'images',
            true,
          )} from your selection will be ignored.`,
        ],
        buttons: { confirm: [{ text: 'Ok' }] },
      });
    }

    let index = 0;
    for (const file of fileListParsed) {
      let processedImage: Blob = file;
      if (file.type === 'image/heic') {
        try {
          const conversionResult = await convertHeic(file);
          processedImage = conversionResult as Blob;
        } catch (error) {
          //@ts-ignore-next-line
          if (error.code !== 1) {
            return showModal({
              title: 'There has been an error',
              buttons: {
                confirm: [{ text: 'Ok' }],
              },
              messages: [
                'There was an error converting the image. Please try manually converting it to a jpeg or png and then try uploading again',
              ],
            });
          }
        }
      }
      const result = resizeImages
        ? await reduceImage(processedImage)
        : { blob: processedImage };

      if (result.error) {
        setProcessingImages(false);
        return showModal({
          title: 'There has been an error',
          buttons: {
            confirm: [{ text: 'Ok' }],
          },
          messages: [result.error],
        });
      } else if (result.blob) {
        const url = URL.createObjectURL(result.blob);
        const { width, height } = await getImageDimensions(url);
        processedImages.push({
          uri: url,
          id: getRandomID(),
          width,
          height,
          isSelected: false,
        });
      }
      index++;
    }

    setCurrentImages((prevImages) => {
      const parsedImages: ProcessedImage[] = [];
      const newImages = [...processedImages];

      prevImages.forEach((image) => {
        if (!image.isSelected || !isReplacing) {
          parsedImages.push(image);
        } else {
          const nextImage = newImages.pop();
          if (nextImage) {
            parsedImages.push(nextImage);
          }
        }
      });

      const nextImages = [...parsedImages, ...newImages];
      return nextImages;
    });

    setProcessingImages(false);
    setIsReplacing(false);
  }

  const handleSelect = (index: number) => {
    const nextImages = currentImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image,
    );
    setCurrentImages(nextImages);
  };

  const handleSelectionDelete = () => {
    const nextImages = currentImages.filter((image) => !image.isSelected);
    setCurrentImages(nextImages);
  };

  const handleSelectionReplace = () => {
    setIsReplacing(true);
  };

  const handleReplaceInLightBox = (index: number) => {
    setSelectedIndex(index);
    handleSelectionReplace();
    const nextImages = currentImages.map((image, i) =>
      i === index ? { ...image, isSelected: true } : image,
    );
    setCurrentImages(nextImages);
  };

  const handleSingleDelete = (index: number) => {
    const nextImages = currentImages.filter((image, idx) => index !== idx);
    setCurrentImages(nextImages);

    if (nextImages.length === 0) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(Math.max(index - 1, 0));
    }
  };

  async function onAddFiles(files: FileList | null) {
    files?.length && processImageFiles(files);
  }

  return (
    <>
      <ImageLightBox
        selectedImageIndex={selectedIndex}
        onClose={() => setSelectedIndex(-1)}
        images={currentImages}
        limit={imageLimit}
        onAddFiles={onAddFiles}
        onDeleteFile={handleSingleDelete}
        onReplaceFile={handleReplaceInLightBox}
      />

      <Box width={300} minHeight={300}>
        <Box sx={{ position: 'relative' }}>
          {processingImages && (
            <Box
              sx={{
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                position: 'absolute',
                backgroundColor: '#FFFFFFEE',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress disableShrink color="secondary" />
                <Typography variant="body1" mt={2} color="secondary">
                  Processing...
                  <br />
                  Please wait...
                </Typography>
              </Stack>
            </Box>
          )}
          {!currentImages?.length ? (
            <DropTarget dropText={prompt} onAddFiles={onAddFiles} />
          ) : (
            <DropWrapper onAddFiles={onAddFiles}>
              <Box sx={{ p: 2, border: '1px solid lightgrey' }}>
                <ImageList
                  variant="quilted"
                  cols={4}
                  rowHeight={62}
                  gap={4}
                  sx={{ m: 0, p: 0 }}
                >
                  {currentImages.map((item, index) => (
                    <ImageListItem
                      sx={{
                        padding: item.isSelected ? 1 : 0,
                        border: item.isSelected
                          ? '1px solid lightgrey'
                          : 'none',
                        borderRadius: 2,
                      }}
                      key={item.uri}
                      cols={index === 0 ? 4 : 1}
                      rows={index === 0 ? 3 : 1}
                    >
                      <img
                        src={handleLocalImage(item.uri)}
                        alt={'Image'}
                        loading="lazy"
                        style={{ borderRadius: 5 }}
                      />
                      <ImageListItemBar
                        onClick={() => setSelectedIndex(index)}
                        sx={{
                          cursor: 'pointer',
                          display: 'flex',
                          flex: 1,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                          height: '100%',
                          background: 'transparent',
                          opacity: item.isSelected ? 1 : 0,
                          '&:hover': {
                            opacity: 1,
                          },
                        }}
                        position="top"
                        actionIcon={
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              handleSelect(index);
                            }}
                            size="small"
                            sx={{
                              '&:hover': {
                                backgroundColor: item.isSelected
                                  ? '#FFFFFFFF'
                                  : '#00000066',
                              },
                              backgroundColor: item.isSelected
                                ? '#FFFFFFFF'
                                : '#00000066',
                              m: 0.5,
                              p: 0,
                            }}
                            aria-label={`Select Image`}
                          >
                            <CheckCircleIcon
                              sx={{
                                fill: item.isSelected
                                  ? colours.brand1
                                  : 'white',
                              }}
                            />
                          </IconButton>
                        }
                        actionPosition="left"
                      />
                    </ImageListItem>
                  ))}
                  {currentImages.length < imageLimit ? (
                    <Box
                      component="label"
                      sx={{
                        cursor: 'pointer',
                        border: '1px solid lightgrey',
                        borderRadius: 1,
                        height: 63,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        flexDirection: 'column',
                        '&:hover': {
                          backgroundColor: colours.light2,
                        },
                      }}
                    >
                      <AddCircleIcon sx={{ fontSize: 30 }} color="primary" />
                      <Typography
                        variant="h6"
                        component="span"
                        color="secondary"
                      >
                        Add
                      </Typography>
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => onAddFiles?.(e.target.files)}
                      />
                    </Box>
                  ) : null}
                </ImageList>
                {hasSelection && (
                  <Stack
                    direction="row"
                    justifyContent="space-around"
                    spacing={1}
                    mt={2}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ display: 'flex', flex: 1 }}
                      component="label"
                      onClick={handleSelectionReplace}
                    >
                      Replace
                      <input
                        type="file"
                        multiple
                        hidden
                        onChange={(e) => onAddFiles?.(e.target.files)}
                      />
                    </Button>
                    <Button
                      onClick={handleSelectionDelete}
                      variant="outlined"
                      color="error"
                      sx={{ display: 'flex', flex: 1 }}
                    >
                      Delete
                    </Button>
                  </Stack>
                )}
              </Box>
            </DropWrapper>
          )}
        </Box>
      </Box>
    </>
  );
}
