import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Suspense, lazy } from 'react';

import CloseIcon from '@mui/icons-material/Close';

const RoomEnvironment = lazy(() => import('./RoomEnvironment.js'));

interface Image360Viewer {
  name: string;
  open: boolean;
  onClose: () => void;
  imageUri: string;
}

export default function Image360Viewer({
  onClose,
  open,
  imageUri,
  name,
}: Image360Viewer) {
  if (!imageUri) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle id="360-viewer" sx={{ backgroundColor: 'secondary.main' }}>
        <Typography sx={{ color: 'white.main' }}>{name}</Typography>
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon sx={{ color: 'white.main' }} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ padding: 0, height: '100%', backgroundColor: 'black' }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Suspense fallback={'...loading'}>
            {/* {open && <RoomEnvironment imageUri={imageUri} />} */}
            {open && <RoomEnvironment imageUri={imageUri} />}
          </Suspense>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
